.preview-page {
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;

    .stripe {
        top: 3rem;
        left: 0;
        right: 0;
        z-index: 5;
        height: 8rem;

        .logo {
            width: 25rem;
        }

        @media screen and (max-width: 992px) {
            height: 5rem;
        }

        @media screen and (max-height: 400px) {
            height: 5rem;
        }

        .logo {
            @media screen and (max-width: 992px) {
                width: 15rem;
            }

            @media screen and (max-height: 400px) {
                width: 15rem;
            }
        }
    }

    .bg-content {
        padding: 4rem;
        width: auto;
        height: auto;
        max-width: 40rem;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% - 20rem);
        z-index: 5;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1);

        &.open {
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
            left: calc(0% - 37rem);

            .button {
                right: -7rem;

                // TODO Mirko
                &.selected {
                    .text-bottom {
                        right: 2.4rem;
                    }
                }
            }
        }

        .button {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            .text-top {
                display: none;
            }
        }
    }

    .home {
        padding: 4rem;
        width: auto;
        height: auto;
        max-width: 40rem;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% - 20rem);
        z-index: 5;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
        opacity: 1;

        &.open {
            transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
            left: calc(0% - 37rem);

            .button {
                transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;

                right: -7rem;

                // TODO Mirko
                &.selected {
                    .text-bottom {
                        right: 2.4rem;
                    }
                }

                @media (hover: hover) {
                    &:hover {
                        transform: translateX(80%);
                    }
                }
            }

            @media screen and (max-width: 992px) {
                opacity: 0;
            }
        }

        .button {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            .text-top {
                display: none;
            }
        }
    }
}

.wrapper-content-preview {
    position: absolute;
    top: 15rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;

    @media screen and (max-width: 992px) {
        top: 15rem;
        left: 3rem;
        right: 3rem;
        transform: translateX(0px) translateY(0px);
        max-height: calc(100vh - 23rem);
        overflow: auto;
    }

    @media screen and (max-height: 600px) {
        top: 15rem;
        left: 3rem;
        right: 3rem;
        transform: translateX(0px) translateY(0px);
        max-height: calc(100vh - 23rem);
        overflow: auto;
    }

    p,
    span {
        color: #ffffff;
    }
}

.main-rows-avatars {
    position: relative;
    top: 15rem;
    z-index: 5;
    max-height: calc(100vh - 23rem);
    overflow: auto;

    .wrapper-back {
        max-width: 80rem;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto 4rem;
        position: relative;
        cursor: pointer;
    }

    p,
    span {
        color: #ffffff;
    }

    .wrapper-row-avatar {
        max-width: 80rem;
        margin: 0 auto 2rem;
        padding: 2rem;

        .content-row {
            display: flex;
            align-items: flex-start;

            @media screen and (max-width: 600px) {
                display: block;
            }
        }

        .avatar {
            margin-right: 2rem;
            max-width: 20rem;
            width: 100%;

            @media screen and (max-width: 600px) {
                margin-bottom: 2rem;
            }
        }
    }
}
