.wrapper-buttons {
    .button {
        margin-bottom: .4rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.button {
    padding: 0.4rem 1rem;
    background-color: $color-dark-400;
    height: 6rem;
    min-width: 25rem;
    font-family: "SM-bold";
    position: relative;
    z-index: 3;
    display: block;
    line-height: 1.2;

    &.locked {
        display: inline-flex;
        align-items: center;
        padding: .4 2rem;
        min-width: auto;
        background-color: $color-info-400;

        span {
            font-size: $large;
            color: $color-danger-400;
        }

        &:hover{
            cursor: not-allowed;
            transform: scale(1);
        }
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.02);
        filter: invert(1);
    }

    .text-bottom {
        color: #ffffff;
        position: absolute;
        bottom: 0.4rem;
        font-size: 1.5rem;
    }

    .text-top {
        position: absolute;
        right: .8rem;
        top: .4rem;
        color: #ffffff;
    }

    &.completed {
        .text-top {
            color: $color-primary-400;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: .1rem;
                background-color: $color-primary-400;
                position: absolute;
                bottom: 1.2rem;
            }
        }
    }

    &.to-complete {
        background-color: $color-primary-400;

        .text-bottom {
            color: $color-dark-400;
        }

        .text-top {
            color: $color-danger-400;
            display: flex;
            align-items: flex-end;

            &::after {
                content: "";
                display: block;
                width: 2.4rem;
                height: 2.4rem;
                margin-left: 1rem;
                background: url("../../assets/images/icons/diamond.png") no-repeat center;
            }
        }
    }
}