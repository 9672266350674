.chapters {
    .logo {
        position: absolute;
        top: 3rem;
        left: -15rem;
        z-index: 3;
    }

    .black-man {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .wrapper-buttons {
        position: absolute;
        top: -.8rem;
        right: 0;
        width: 31rem;
    }

}