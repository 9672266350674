body {
    font-family: "SM-bold";
    font-size: $default;
    color: $color-dark-400;
    text-transform: uppercase;
}

p {
    font-family: "SM-bold";
    font-size: $default;
    color: $color-dark-400;
    margin-bottom: 0 !important;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

h1 {
    font-family: "SM-bold";
    font-size: $large;
    color: $color-dark-400;
    margin-bottom: 0;
    line-height: 1;
}

h2 {
    font-family: "SM-bold";
    font-size: $large;
    color: $color-dark-400;
    margin-bottom: 0;
    line-height: 1;
}

h3 {
    font-family: "SM-bold";
    font-size: $large;
    color: $color-dark-400;
    margin-bottom: 0;
    line-height: 1;
}

h4 {
    font-family: "SM-bold";
    font-size: $default;
    color: $color-dark-400;
    margin-bottom: 0;
}

h5 {
    font-family: "SM-bold";
    font-size: $default;
    color: $color-dark-400;
    margin-bottom: 0;
}

h6 {
    font-family: "SM-bold";
    font-size: $small;
    color: $color-dark-400;
    margin-bottom: 0;
}

thead {
    th {
        font-family: "SM-bold";
        font-size: $default;
        color: $color-dark-400;
    }
}

strong {
    font-family: "SM-bold";
}

ul {
    font-size: $default;
}

small {
    font-size: $small;
}

label {
    font-family: "SM-bold";
    margin-bottom: 0.4rem;
    text-transform: uppercase;
    font-size: $default;
}

.color-secondary-400 {
    color: $color-secondary-400;
}

.font-dafault {
    font-size: $default;
}

.font-large {
    font-size: $large;
}

.font-small {
    font-size: $small;
}

.text-underline-custom {
    position: relative;
    text-decoration: underline;
    text-underline-offset: -0.25em;
    text-decoration-skip-ink: none;
    text-decoration-color: #000000;
    text-decoration-thickness: 0.1rem;
}

.link {
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

.text-danger {
    color: $color-danger-400;
}