.info-page {
    background-color: #000000;

    .logo {
        position: absolute;
        top: -14rem;
        right: -16rem;
    }

    .stripe {
        height: 6rem;

        p {
            line-height: 1.2;
        }
    }

    .icon-arrow-right {
        position: absolute;
        right: 4rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        animation: bounce 1s ease infinite;
    }
}

@keyframes bounce {
    0% { transform:translate(0%, -50%); }
    50% { transform:translate(-15%, -50%); }
    100% { transform:translate(0%, -50%); }
}