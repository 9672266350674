.sender-bar{
  width: calc(100% + 4rem);
  background-color: $dark-200;
  margin: 0 -1.5rem;
  display: flex;
  padding: 1rem 2rem;
  border-top-right-radius: 3.1rem;
  border-top-left-radius: 3.1rem;
  box-shadow: 0 3px 6px rgb(0 0 0 / 40%);
  align-items: center;

  img{
    height: 3rem;
    width: 3rem;
    margin-right: 3rem;

  }

  .sender-name{
    color:#fff;
  }

  &.group{
    .sender-name{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.6rem;
    }

    img{
      margin-right: 1rem;
    }
  }
}