@charset "UTF-8";

/* http://clubmate.fi/scss-font-face-mixin/ */

@mixin font-face($style-name, $file, $family, $fontweight, $fontstyle, $category:"") {
    $filepath: "../../assets/fonts/" + $family + "/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src:url($filepath + ".eot");
        src:
            url($filepath + ".eot?#iefix") format('embedded-opentype'),
            url($filepath + ".woff") format('woff'),
            url($filepath + ".ttf")  format('truetype'),
            url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $fontweight;
        font-style: $fontstyle;
    }
    %#{$style-name} {
        font: {
            @if $category != "" {
                font-family: "#{$style-name}", #{$category};
            }
            @else {
                font-family: "#{$style-name}";
                font-weight: normal;
            }
        }
    }
}


/* @include font-face($style-name, $file, $family, $fontweight, $fontstyle, $category);  */
/* @include font-face(NOME, FILE, CARTELLA, PESO, STYLE, SERIF);  */

// SpaceMono Bold
@include font-face('SM-bold', 'SpaceMono-Bold', 'SpaceMono', 700, normal, 'sans serif');
