.wrapper-range-position {
    position: absolute;
    bottom: 4.5rem;
    right: 5rem;
    width: 19rem;
    z-index: 4;
}

// Range Slider
$range-width: 100%;
$range-handle-size: 1.6rem;

.range-slider__range {
    -webkit-appearance: none;
    width: 100%;
    height: .2rem;
    background: $color-primary-400;
    outline: none;
    padding: 0;
    margin: 0;

    // Range Handle
    &::-webkit-slider-thumb {
        appearance: none;
        width: $range-handle-size;
        height: $range-handle-size;
        border-radius: 50%;
        background: $color-primary-400;
        cursor: pointer;
        border: .2rem solid $color-primary-700;
        transition: background .15s ease-in-out;

        &:hover {
            background: $color-primary-700;
        }
    }

    &:active::-webkit-slider-thumb {
        background: $color-primary-700;
    }

    &::-moz-range-thumb {
        width: $range-handle-size;
        height: $range-handle-size;
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        transition: background .15s ease-in-out;

        &:hover {
            background: $color-primary-700;
        }
    }

    &:active::-moz-range-thumb {
        background: $color-primary-700;
    }

    // Focus state
    &:focus {

        &::-webkit-slider-thumb {
            box-shadow: 0 0 0 .2rem $color-primary-700;
        }
    }
}