html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    overscroll-behavior-y: none;
    height: 100%;
    
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

body {
    height: 100%;
    background-color: #000000;
    overscroll-behavior-y: none;
    &:after {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        background: url(../../assets/images/layout/scratch.png) repeat center;
        pointer-events: none;
    }
}

img {
    image-rendering: auto;
    image-rendering: -webkit-optimize-contrast;
}

.disabled {
    opacity: 0.15;
    cursor: not-allowed;

    &:hover {
        cursor: not-allowed;
    }

    &:active {
        pointer-events: none;
    }

    * {
        &:hover {
            cursor: not-allowed !important;
        }
    }
}

.black-man, .logo {
    pointer-events: none;
}

.container-fluid {
    width: 100%;
    padding-right: $m-middle;
    padding-left: $m-middle;
}

.container {
    max-width: 128rem;
    padding-right: $m-middle;
    padding-left: $m-middle;
    margin-left: auto;
    margin-right: auto;
}

.title-sticky {
    position: sticky;
    top: 10rem;
}

.icon {
    width: 2.4rem;
    height: 2.4rem;
    flex: 0 0 2.4rem;
}

.list {
    &-numeric {
        padding-left: 2rem;

        li {
            list-style: initial;
            list-style-type: decimal;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }

    &-disc {
        padding-left: 2rem;

        li {
            list-style: initial;
            list-style-type: disc;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}

hr {
    height: 0.1rem;
    width: 100%;
    background-color: $color-secondary-400;
    margin: 0;

    &.dotted {
        border-top: 0.1rem dotted $color-secondary-400;
    }
}

.row {
    margin-left: -$m-middle;
    margin-right: -$m-middle;
}

.row {
    &.no-gutters {
        margin-right: 0;
        margin-left: 0;
    }
}

.modal-open {
    .aos-animate {
        transform: initial !important;
    }
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-right: 2rem;
    padding-left: 2rem;
}

.mb-auto {
    margin-bottom: auto !important;
}

.mb-1 {
    margin-bottom: $m-base !important;
}

.mb-2 {
    margin-bottom: $m-middle !important;
}

.mb-3 {
    margin-bottom: $m-notable !important;
}

.mb-4 {
    margin-bottom: $m-large !important;
}

.mb-5 {
    margin-bottom: $m-big !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mt-1 {
    margin-top: $m-base !important;
}

.mt-2 {
    margin-top: $m-middle !important;
}

.mt-3 {
    margin-top: $m-notable !important;
}

.mt-4 {
    margin-top: $m-large !important;
}

.mt-5 {
    margin-top: $m-big !important;
}

.ml-auto {
    margin-left: auto !important;
}

.ml-1 {
    margin-left: $m-base !important;
}

.ml-2 {
    margin-left: $m-middle !important;
}

.ml-3 {
    margin-left: $m-notable !important;
}

.mr-auto {
    margin-right: auto !important;
}

.mr-1 {
    margin-right: $m-base !important;
}

.mr-2 {
    margin-right: $m-middle !important;
}

.mr-3 {
    margin-right: $m-notable !important;
}

.p-1 {
    padding: $m-base !important;
}

.p-2 {
    padding: $m-middle !important;
}

.p-3 {
    padding: $m-notable !important;
}

.p-4 {
    padding: $m-large !important;
}

.p-5 {
    padding: $m-big !important;
}

.pt-1 {
    padding-top: $m-base !important;
}

.pt-2 {
    padding-top: $m-middle !important;
}

.pt-3 {
    padding-top: $m-notable !important;
}

.pt-4 {
    padding-top: $m-large !important;
}

.pt-5 {
    padding-top: $m-big !important;
}

.pb-1 {
    padding-bottom: $m-base !important;
}

.pb-2 {
    padding-bottom: $m-middle !important;
}

.pb-3 {
    padding-bottom: $m-notable !important;
}

.pb-4 {
    padding-bottom: $m-large !important;
}

.pb-5 {
    padding-bottom: $m-big !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pr-1 {
    padding-right: $m-base !important;
}

.pr-2 {
    padding-right: $m-middle !important;
}

.pr-3 {
    padding-right: $m-notable !important;
}

.pr-4 {
    padding-right: $m-large !important;
}

.pr-5 {
    padding-right: $m-big !important;
}

.pl-1 {
    padding-left: $m-base !important;
}

.pl-2 {
    padding-left: $m-middle !important;
}

.pl-3 {
    padding-left: $m-notable !important;
}

.pl-4 {
    padding-left: $m-large !important;
}

.pl-5 {
    padding-left: $m-big !important;
}

.disable-select {
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

ul {
    &.disc {
        padding-left: 2rem;
        margin-bottom: 2rem;

        li {
            padding-left: 1rem;
            list-style: disc;
        }
    }

    &.number {
        padding-left: 2rem;
        margin-bottom: 2rem;

        li {
            padding-left: 1rem;
            list-style: decimal;
        }
    }
}
