.stripe {
    position: absolute;
    left: -4rem;
    right: -4rem;
    background-color: $color-primary-400;
    height: 10rem;
    z-index: 2;
    .logo {
        position: absolute;
        left: 7rem;
    }
    .settings {
        position: absolute;
        right: 5rem;
        top: 50%;
        transform: translateY(-50%) scale(1);
        z-index: 2;
        transition: transform 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
            transform: translateY(-50%) scale(1.1);
        }
    }
}
