.icon-back {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
    background: url("../../assets/images/icons/back.png") no-repeat center;
    border: 0;
    cursor: pointer;

    &.white {
        background: url("../../assets/images/icons/back-w.png") no-repeat center;
    }
}