.choice {
    .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 9;

        &:hover {
            cursor: pointer;
        }
    }

    .wrapper-buttons {
        position: absolute;
        bottom: -3rem;
        left: 0;
        z-index: 4;

        .button {
            .text-bottom {
                font-size: $small;
            }
        }
    }

    .stripe {
        padding-right: 4rem;
        bottom: 6rem;
        height: auto;
        padding: 1rem 4rem 1rem 30rem;
        z-index: 3;

        p {
            font-size: $small;
        }
    }

    .bg-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 2;
        // filter: grayscale(1) saturate(3) contrast(3) invert(1) brightness(0.9);
        background: linear-gradient(to top, #3204fdba, #9907facc), url("../../assets/images/layout/vid.png") no-repeat top center;
    }
}