.chat {
    .close {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 9;

        &:hover {
            cursor: pointer;
        }
    }

    .wrapper-buttons {
        position: absolute;
        bottom: -3rem;
        right: 0;
        z-index: 99;

        .button {
            .text-bottom {
                font-size: $small;
            }
        }

        &.mirror {
            right: unset;
            left: 0;
        }
    }

    .wrapper-phone {
        position: absolute;
        bottom: 0;
        left: 4rem;
        height: 35rem;
        width: 32rem;
        padding: 1.5rem 4rem 2rem 3rem;
        z-index: 3;
        background: url("../../assets/images/layout/phone.png") no-repeat bottom center;
        background-size: contain;
        transform: translateY(100000px);

        &.mirror {
            left: unset;
            right: 4rem;
        }

        .wrapper-chat {
            height: 29rem;
            overflow-y: scroll;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            padding-bottom: 2rem;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .chat-video {
        position: absolute;
        top: 0;
        left: 0;
    }
}
