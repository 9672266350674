/* ==========  RESET  ========== */

a,
abbr,
acronym,
blockquote,
body,
cite,
code,
dd,
div,
dl,
dt,
fieldset,
form,
html,
iframe,
img,
label,
legend,
li,
object,
ol,
pre,
q,
small,
span,
strike,
sub,
ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
}

li,
ol,
ul {
    list-style: none;
}

button:focus {
    outline: 0;
}

.row > * {
    padding-left: 0;
    padding-right: 0;
}
