/*------------------------------------*\
    COLORS
\*------------------------------------*/

//Yellow
$color-primary-700: #FF7232;
$color-primary-400: #FFFF66;

//Black
$dark-200: #353535;
$color-dark-400: #27221F;

//Pink
$color-secondary-400: #FF66FF;

//Red
$color-danger-400: #FB4C4C;

//Blue
$color-info-400: #009ADB;



/*------------------------------------*\
    FONT SIZE
\*------------------------------------*/

$large: 2.8rem;
$default: 2rem;
$small: 1.4rem;

/*------------------------------------*\
    MEASURES
\*------------------------------------*/
$m-big: 5rem;
$m-large: 4rem;
$m-notable: 3rem;
$m-middle: 2rem;
$m-base: 1rem;
